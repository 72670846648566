import { Injectable } from '@angular/core';
import { AuthenticationService } from '@sama/ngx-components';
import { TokenService } from '@sama/ngx-events';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class GoTokenService implements TokenService {
  constructor(private authenticationService: AuthenticationService) {}

  getToken(): Promise<string> {
    return lastValueFrom(this.authenticationService.getTokenSilently$());
  }
}
