import { OverlayModule } from '@angular/cdk/overlay';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  BreadcrumbService,
  IssuesService,
  ProjectsService,
  RollbarErrorHandler,
  RollbarService,
  legacyConfigProvider,
  rollbarFactory,
} from '@sama/common';
import {
  AuthModule,
  ConfigService,
  defaultAuthenticationScope,
} from '@sama/ngx-components';
import { EVENTS_ENVIRONMENT, EventTrackingModule } from '@sama/ngx-events';
import { provideSettings } from '@sama/settings-ui';
import { ROLLBAR_CONFIG } from '@sama/ui-library';
import { lastValueFrom } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutsModule } from './modules/base/layouts/layouts.module';
import { LogoutModule } from './modules/base/logout/logout.module';
import { DiagnosticsModule } from './modules/diagnostics/diagnostics.module';
import { HomePageModule } from './modules/home-page/home-page.module';
import { GoTokenService } from './services/go-token.service';
import { GoInterceptorUrlsService } from './services/interceptor-urls.service';
import { Auth0Scope } from './shared/auth0-scope.enum';
import { product } from './shared/events-root-context-values';
import { AppStoreModule } from './store/app-store.module';

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    AuthModule.forRoot({
      scope: [...defaultAuthenticationScope, Auth0Scope.MESSAGE_CREATE],
      interceptorUrlsService: GoInterceptorUrlsService,
    }),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    EventTrackingModule.forRoot({
      product: product,
      tokenService: GoTokenService,
    }),
    AppStoreModule,
    OverlayModule,
    FontAwesomeModule,
    DiagnosticsModule,
    LayoutsModule,
    HomePageModule,
    LogoutModule,
    RouterModule,
  ],
  providers: [
    ProjectsService,
    BreadcrumbService,
    IssuesService,
    {
      provide: ROLLBAR_CONFIG,
      useValue: {
        accessToken: PORTAL_CONFIG.rollbar.accessToken,
        environment: PORTAL_CONFIG.rollbar.environment,
      },
    },
    provideSettings({
      apiBaseUrl: PORTAL_CONFIG.settings.baseUrl,
      enablePersistence: PORTAL_CONFIG.settings.enablePersistence,
    }),
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService) => async () => {
        await lastValueFrom(configService.load());
      },
      deps: [ConfigService],
      multi: true,
    },
    {
      provide: EVENTS_ENVIRONMENT,
      useFactory: () => {
        return PORTAL_CONFIG.events.environment;
      },
    },
    {
      provide: Window,
      useValue: window,
    },
    {
      provide: ErrorHandler,
      useClass: RollbarErrorHandler,
    },
    {
      provide: RollbarService,
      useFactory: rollbarFactory,
      deps: [ConfigService],
    },
    legacyConfigProvider,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
