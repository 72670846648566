import { Injectable } from '@angular/core';
import { AuthenticationService } from '@sama/ngx-components';
import { ILogoutService } from '@sama/ui-library';
import jwt_decode from 'jwt-decode';
import { Observable, map } from 'rxjs';
import { AccessTokenFields } from '../models/auth0/access-token-constants';

@Injectable({
  providedIn: 'root',
})
export class UserManagerService implements ILogoutService {
  constructor(private authenticationService: AuthenticationService) {}

  getHubUserId$(): Observable<number> {
    return this.authenticationService.getTokenSilently$().pipe(
      map((token: string) => {
        const decodedToken: any = jwt_decode(token!);
        return decodedToken[AccessTokenFields.HubUserId] as number;
      }),
    );
  }

  isClientUser$(): Observable<boolean> {
    return this.authenticationService.getTokenSilently$().pipe(
      map((token: string) => {
        const decodedToken: any = jwt_decode(token!);
        let clientId = decodedToken[AccessTokenFields.ClientId] as number;
        return !!clientId;
      }),
    );
  }

  logout() {
    this.authenticationService.logout();
  }
}
