import {
  Observable,
  ReplaySubject,
  lastValueFrom,
  map,
  of,
  switchMap,
} from 'rxjs';

import { Inject, Injectable } from '@angular/core';
import { AuthorizationService } from '@sama/angular-auth';
import { QueryOptions } from '@sama/ngx-components';
import { BreadcrumbNavigation } from '@sama/ui-library/breadcrumb';
import { NavigationType } from '@sama/ui-library/shared/public';
import { Tab } from '@sama/ui-library/tabs';
import { retry, shareReplay } from 'rxjs/operators';
import { ProjectType } from '../models/project/project-type.enum';
import { Project } from '../models/project/project.model';
import { BreadcrumbService } from '../services/breadcrumb.service';
import { FeatureFlagsService } from '../services/feature-flags.service';
import { ProjectsService } from './projects.service';

export interface ITab extends Tab {
  readonly isVisible$?: (project: Project) => Observable<boolean>;
}

const LOAD_PROJECT_RETRY_COUNT = 2;

@Injectable({ providedIn: 'root' })
export class CurrentManagedProjectService {
  public projectSubject = new ReplaySubject<Project>(1);
  private wasProjectFetched = false;
  private projectId!: number;
  private samahubUrl?: string;

  constructor(
    private featureFlagsService: FeatureFlagsService,
    private projectsService: ProjectsService,
    private breadcrumbService: BreadcrumbService,
    @Inject(AuthorizationService)
    private authorizationService: AuthorizationService,
  ) {}

  private getSamahubUrlFromFeatureFlags() {
    return this.featureFlagsService
      .hasFlag$(PORTAL_CONFIG.featureFlags.prototypeFlag ?? '', this.projectId)
      .pipe(
        map((res) => {
          return res
            ? (PORTAL_CONFIG.featureFlags.prototypeUrl ??
                PORTAL_CONFIG.samahubUrl)
            : PORTAL_CONFIG.samahubUrl;
        }),
      );
  }

  public getSamahubUrl(): string {
    return this.samahubUrl ?? PORTAL_CONFIG.samahubUrl;
  }

  public getTabs$(currentUrl: string): Observable<ITab[]> {
    const tabs: ITab[] = [
      {
        url: `/${currentUrl}/dashboard`,
        label: 'Dashboard',
        type: NavigationType.REDIRECT_NAVIGATION,
        isVisible$: (project: Project) => {
          return this.authorizationService.isAuthorized$([
            {
              action: 'dashboard',
              resourceType: 'project',
              resourceId: `${project.id}`,
            },
          ]);
        },
      },
      {
        url: `/${currentUrl}/tasks`,
        label: 'Tasks',
        type: NavigationType.REDIRECT_NAVIGATION,
        isVisible$: (project: Project) => {
          return this.authorizationService.isAuthorized$([
            {
              action: 'task_search',
              resourceType: 'project',
              resourceId: `${project.id}`,
            },
          ]);
        },
      },
      {
        url: `/${currentUrl}/sampling`,
        label: 'Sampling',
        type: NavigationType.REDIRECT_NAVIGATION,
        isVisible$: (project: Project) => {
          return this.authorizationService.isAuthorized$([
            {
              action: 'sample_create',
              resourceType: 'project',
              resourceId: `${project.id}`,
            },
          ]);
        },
      },
      {
        url: `/${currentUrl}/calibration`,
        label: 'Calibration',
        type: NavigationType.REDIRECT_NAVIGATION,
        isVisible$: (project: Project) => {
          return this.authorizationService.isAuthorized$([
            {
              action: 'issue_read',
              resourceType: 'project',
              resourceId: `${project.id}`,
            },
          ]);
        },
      },
      {
        url: `/${currentUrl}/trainingTemplates`,
        label: 'Training Templates',
        type: NavigationType.REDIRECT_NAVIGATION,
        isVisible$: (project: Project) => {
          if (project.projectType !== ProjectType.CERTIFICATION) {
            return of(false);
          }

          return this.authorizationService.isAuthorized$([
            {
              action: 'task_template_read',
              resourceType: 'project',
              resourceId: `${this.projectId}`,
            },
          ]);
        },
      },
    ];

    return of(tabs).pipe(shareReplay(1));
  }

  public resetProject(): void {
    this.projectSubject = new ReplaySubject<Project>(1);
    this.wasProjectFetched = false;
    this.breadcrumbService.clearNavigation();
  }

  public async loadProject(projectId: number): Promise<void> {
    if (!this.wasProjectFetched) {
      const queryOptions = new QueryOptions({
        joins: ['projectGroup', 'client'],
      });
      const fetchedProject = await lastValueFrom(
        this.projectsService.findById(projectId, queryOptions).pipe(
          switchMap((project: Project) => {
            this.projectId = project.id;
            const samahubUrl$ = this.getSamahubUrlFromFeatureFlags();
            return samahubUrl$.pipe(
              map((samahubUrl) => {
                this.samahubUrl = samahubUrl;
                this.breadcrumbService.updateConfig({
                  navigation: this.getBreadcrumbNavFromProject(project),
                  projectType: project.projectType,
                  projectStatus: project.state,
                });
                this.wasProjectFetched = true;
                return project;
              }),
            );
          }),
          retry(LOAD_PROJECT_RETRY_COUNT),
        ),
      );
      this.projectSubject.next(fetchedProject);
    }
  }

  getBreadcrumbNavFromProject(project: Project): BreadcrumbNavigation {
    const projectGroupLabel =
      project.projectGroup!.name +
      (project.projectGroup?.workflowCode
        ? ` (${project.projectGroup?.workflowCode})`
        : '');
    return {
      clientCode: project.client!.code,
      navigation: [
        {
          label: project.client!.name,
          url: `${this.samahubUrl}/clients/${project.client!.id}`,
          type: NavigationType.REDIRECT_NAVIGATION,
        },
        {
          label: projectGroupLabel,
          url: `${this.samahubUrl}/clients/${
            project.client!.id
          }/project_groups/${project.projectGroup!.id}`,
          type: NavigationType.REDIRECT_NAVIGATION,
        },
      ],
    };
  }
}
