import { Injectable } from '@angular/core';
import { BreadcrumbNavigation } from '@sama/ui-library/breadcrumb';
import { NavigationType } from '@sama/ui-library/shared/public';
import { BehaviorSubject } from 'rxjs';
import { BreadcrumbConfig } from '../models/breadcrumb/breadcrumb-config';
import { ProjectState } from '../models/project/project-state.enum';
import { capitalize } from '../shared/utils';

@Injectable()
export class BreadcrumbService {
  public readonly emptyNavigation: BreadcrumbNavigation = {
    navigation: [
      {
        label: '',
        url: '',
        type: NavigationType.REDIRECT_NAVIGATION,
      },
    ],
  };

  private config = new BehaviorSubject<BreadcrumbConfig>({
    navigation: this.emptyNavigation,
    projectStatus: null,
    projectType: null,
  });

  public config$ = this.config.asObservable();

  updateConfig(config: Partial<BreadcrumbConfig>) {
    if (config.projectStatus) {
      if (config.projectStatus == ProjectState.DRAFT) {
        config.projectStatus = ProjectState.PAUSED;
      }

      config.projectStatus = capitalize(config.projectStatus);
    }

    const currentConfig = this.config.getValue();
    this.config.next({ ...currentConfig, ...config });
  }

  clearNavigation() {
    this.updateConfig({
      navigation: this.emptyNavigation,
      projectStatus: null,
      projectType: null,
    });
  }
}
