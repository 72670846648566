import { Component } from '@angular/core';
import { HealthService } from './health.service';

@Component({
  selector: 'app-health',
  templateUrl: './health.component.html',
})
export class HealthComponent {
  healthStatus: Promise<String>;

  constructor(private service: HealthService) {
    this.healthStatus = this.getHealthCheck();
  }

  async getHealthCheck(): Promise<string> {
    return this.service.checkStatus();
  }
}
