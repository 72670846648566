import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BodyComponent } from './body.component';

@NgModule({
  declarations: [BodyComponent],
  exports: [BodyComponent],
  imports: [CommonModule],
})
export class BodyModule {}
