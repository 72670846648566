import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SettingsItemsConfig } from '../shared/settings-items.config';

@Injectable({
  providedIn: 'root',
})
export class SettingsItemsService {
  private config = new BehaviorSubject<SettingsItemsConfig>({
    label: '',
    items: [],
    visible: false,
  });
  config$ = this.config.asObservable();

  updateConfig(config: Partial<SettingsItemsConfig>) {
    const currentConfig = this.config.getValue();
    this.config.next({ ...currentConfig, ...config });
  }
}
