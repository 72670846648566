import { Observable, of } from 'rxjs';
import { ProjectStats } from '../../models/project/project-stats.interface';
import { Project } from '../../models/project/project.model';
import {
  createProject,
  createProjectStats,
} from '../../test-helpers/entity-creators';
import { BaseCrudServiceMock } from './base-crud.service';

export class ProjectsServiceMock extends BaseCrudServiceMock<Project> {
  getProjectStats(): Observable<ProjectStats> {
    return of(createProjectStats());
  }

  create(): Observable<Project> {
    return of(createProject());
  }
}
