export enum AccessTokenFields {
  Id = 'id',
  Type = 'type',
  Sub = 'sub',
  ExpiresIn = 'expiresIn',
  Issuer = 'issuer',
  Scope = 'scope',
  Permissions = 'permissions',
  Audience = 'audience',
  ClientId = 'https://accounts.sama.com/clientId',
  HubUserId = 'https://accounts.sama.com/hubUserId',
}

export interface JwtPayload {
  id?: string;
  type?: string;
  sub?: string;
  expiresIn?: string;
  issuer?: string;
  scope?: string;
  permissions?: string[];
  audience?: string;
  'https://accounts.sama.com/clientId'?: number;
  'https://accounts.sama.com/hubUserId'?: number;
}
