<app-base-dialog
  [width]="data.width"
  [height]="data.height"
  [title]="data.title"
  [icon]="data.icon"
  class="dialog"
>
  <div class="body" body>
    <p class="body__title">{{ data.message }}</p>
    <div *ngIf="data.details" class="body__view-details">
      View details
      <button mat-icon-button (click)="toggleViewDetails()">
        <mat-icon>{{
          showViewDetails ? 'expand_less' : 'expand_more'
        }}</mat-icon>
      </button>
    </div>
    <ul *ngIf="data.details && showViewDetails" class="details">
      <li *ngFor="let detail of data.details" class="details__title">
        {{ detail }}
      </li>
    </ul>
  </div>
  <div class="dialog__action" actions>
    <button
      mat-flat-button
      disableRipple="true"
      (click)="onConfirmClick()"
      class="action-button"
      cdkFocusRegionStart
    >
      {{ data.confirmBtnText || 'OK' }}
    </button>
  </div>
</app-base-dialog>
