import { FindPageResult, QueryOptions } from '@sama/ngx-components';
import { Observable, of } from 'rxjs';
import { IssueComment } from '../../models/issue/issue-comment.model';
import { IssuesStats } from '../../models/issue/issue-stats.interface';
import { Issue } from '../../models/issue/issue.model';
import {
  createComment,
  createIssue,
  createNAmountOfIssues,
} from '../../test-helpers/entity-creators';

export class IssuesServiceMock {
  projectId!: number;

  constructor() {}

  findPage(
    projectId: number,
    filters?: Object,
  ): Observable<FindPageResult<Issue>> {
    const issues = createNAmountOfIssues(5);
    return of({
      data: issues,
      count: issues.length,
      total: issues.length,
      page: 1,
      pageCount: 1,
    });
  }

  getOne(projectId: number, issueId: string): Observable<Issue | null> {
    return of();
  }

  deleteOne(
    issueId: string,
    taskId: string,
    projectId: number,
  ): Observable<void> {
    return of();
  }

  getIssuesStats(projectId: number): Observable<IssuesStats> {
    return of({
      totalCount: 0,
      typeCount: [],
      statusCount: [],
      parkedTaskCount: 0,
    });
  }

  updateOne(
    issueId: string,
    projectId: number,
    taskId: string,
    issue: Partial<Issue>,
  ): Observable<Issue> {
    return of(createIssue());
  }

  getComments(
    projectId: number,
    taskId: string,
    issueId: string,
    options?: QueryOptions,
  ): Observable<FindPageResult<IssueComment>> {
    return of({
      data: [createComment(), createComment()],
      count: 2,
      total: 2,
      page: 1,
      pageCount: 1,
    });
  }
}
