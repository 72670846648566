import { IssueAttachment } from './issue-attachment.model';
import { IssueComment } from './issue-comment.model';
import { IssueStatus } from './issue-status.enum';
import { IssueTypeEnum } from './issue-type.enum';

export const issueTypeDisplayNameMapper: Record<IssueTypeEnum, string> = {
  'instruction-gap': 'Instruction gap',
  subjectivity: 'Subjectivity',
  'sensor-issue': 'Sensor issue',
  'edge-case': 'Edge case',
  other: 'Other',
};

const issueStatusDisplayNameMapper: Record<IssueStatus, string> = {
  new: 'New',
  open: 'Open',
  closed: 'Closed',
};

export class Issue {
  id!: string;
  projectId!: number;
  taskId!: string;
  type!: IssueTypeEnum;
  status!: IssueStatus;
  title!: string;
  description!: string;
  tags!: string[];
  parkTasks!: boolean;
  location!: object;
  createdBy!: number;
  createdAt!: string;
  updatedBy!: number | null;
  updatedAt!: string | null;
  closedBy!: number | null;
  closedAt!: string | null;
  issueType!: {
    type: IssueTypeEnum;
    name: string;
  };
  isTaskParked!: boolean;
  attachments?: IssueAttachment[];
  comments?: IssueComment[];
  userFullName!: string;

  public constructor(init?: Partial<Issue>) {
    Object.assign(this, init);
  }
}
