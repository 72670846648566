<div class="container">
  <div class="container__header">
    <h1 class="title">
      Welcome to your data insights
      <br />
      <span class="title__highlight">& annotation space</span>
    </h1>
    <div class="top-right-design">
      <img
        src="assets/icons/home-page-design.svg"
        alt="Background decoration with Geometric design"
      />
    </div>
  </div>
  <div class="container__body">
    <app-projects-list></app-projects-list>
  </div>
</div>
