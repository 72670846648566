export class MessagingServiceMock {
  constructor() {}

  public showSuccess(message: string): void {}

  public showError(message: string): void {}

  public showInfo(message: string): void {}

  public showCustomizedIcon(icon: string, message: string): void {}
}
