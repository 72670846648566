<div class="container">
  <div
    class="container__header"
    [ngClass]="{ sticky: sticky, 'container__header--spaced': headerSpacing }"
  >
    <!-- Header -->
    <div>
      <h1 class="container__title">{{ title }}</h1>
      <span *ngIf="subTitle" class="container__subtitle">{{ subTitle }}</span>
    </div>
    <ng-content select="[header]"></ng-content>
  </div>
  <div class="container__body">
    <!-- Body -->
    <ng-content></ng-content>
  </div>
</div>
