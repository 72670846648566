import { Provider } from '@angular/core';
import { ConfigService } from '@sama/ngx-components';
import { Observable, of } from 'rxjs';

// This adapter provides our config via the API they expect. Once we no longer
// use ngx-components, this service can be deleted.

class LegacyConfigService {
  get(path: string): any {
    return path
      .split('.')
      .reduce((config: any, key) => config?.[key], PORTAL_CONFIG);
  }

  load(): Observable<any> {
    return of(PORTAL_CONFIG);
  }
}

export const legacyConfigProvider: Provider = {
  provide: ConfigService,
  useValue: new LegacyConfigService(),
};
