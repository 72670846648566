import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  AuthenticationService,
  BaseService,
  LoggingService,
  MessagingService,
} from '@sama/ngx-components';
import jwt_decode from 'jwt-decode';
import * as Rollbar from 'rollbar';
import { Observable, catchError, map, of, switchMap, tap } from 'rxjs';
import { AccessTokenFields } from '../models/auth0/access-token-constants';
import { RollbarService } from '../services/rollbar-error-handler.service';

export const FEATURE_FLAGS_API_VERSION = 'v2.0';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsService extends BaseService {
  private baseApiUrl: string;
  private flags: Record<number, string[]> = {};

  constructor(
    @Inject(RollbarService)
    private rollbar: Rollbar,
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    loggingService: LoggingService,
    messagingService: MessagingService,
  ) {
    super(messagingService, loggingService);
    this.baseApiUrl = PORTAL_CONFIG.featureFlags.backendBaseUrl;
  }

  hasFlag$(flag: string, projectId = 0): Observable<boolean> {
    if (!this.flags[projectId]) {
      return this.loadFeatureFlags$(projectId).pipe(
        map((projectFlags) => projectFlags.includes(flag)),
      );
    } else {
      return of(this.flags[projectId].includes(flag));
    }
  }

  private loadFeatureFlags$(projectId: number): Observable<string[]> {
    return this.getHubUserId$().pipe(
      switchMap((hubUserId: number) => {
        // if we sent a 0 as a project ID, the Feature Flags API
        // will consider this as an OR instead of an AND, so we will get
        // all the feature flags for the particular user no matter the project
        // project ID is required
        const url = `${this.baseApiUrl}/${FEATURE_FLAGS_API_VERSION}/agents/${hubUserId}/experiments?project_id=${projectId}`;

        return this.http.get<string[]>(url).pipe(
          catchError((error) => {
            this.rollbar.error(
              new Error('Failed retrieving feature flags').stack,
            );
            return this.handleError('getFeatureFlags')(error);
          }),
        );
      }),
      // return an empty array if any error occurs
      catchError((_) => of([])),
      tap((flags) => (this.flags[projectId] = flags)),
    );
  }

  private getHubUserId$(): Observable<number> {
    return this.authenticationService.getTokenSilently$().pipe(
      map((token: string) => {
        const decodedToken: any = jwt_decode(token!);
        return decodedToken[AccessTokenFields.HubUserId] as number;
      }),
    );
  }
}
