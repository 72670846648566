import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Gets lastUpdatedDate formatted as MM/DD/YY - HH:MM AM/PM
 * @input date string
 * @returns date with format MM/DD/YY - HH:MM AM/PM
 */
export function formatLastUpdatedDate(date: string): string {
  const dateToFormat = new Date(date);
  return `${dateToFormat.toLocaleDateString([], {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  })} - ${dateToFormat.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })}`;
}

export function capitalize(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export const framesValidator: ValidatorFn = (
  group: AbstractControl,
): ValidationErrors | null => {
  const startFrameControl = group.get('startFrame');
  const endFrameControl = group.get('endFrame');

  const startFrame = startFrameControl?.value;
  const endFrame = endFrameControl?.value;

  if (
    (startFrame == null && endFrame != null) ||
    (startFrame != null && endFrame == null) ||
    (startFrame != null && endFrame != null && endFrame <= startFrame) ||
    startFrame < 0 ||
    endFrame < 0
  ) {
    startFrameControl?.markAsTouched();
    endFrameControl?.markAsTouched();
    startFrameControl?.setErrors({ invalidFrames: true });
    endFrameControl?.setErrors({ invalidFrames: true });
    return { invalidFrames: true };
  }

  startFrameControl?.setErrors(null);
  endFrameControl?.setErrors(null);
  return null;
};

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
