import { BehaviorSubject, Observable, of } from 'rxjs';
import { generateJwt } from '../../test-helpers/access-token';

export class AuthenticationServiceMock {
  public logInSub = new BehaviorSubject<string | null>(null);

  public silentLogInSub = new BehaviorSubject<string | null>(null);

  get isAuthenticated$(): Observable<boolean> {
    return of(true);
  }

  login(redirectPath: string = '/'): void {}

  logout(): void {}

  getTokenSilently$(): Observable<string> {
    return of(generateJwt({}));
  }
}
