// The bootstrap effects injection token is because the effects are initialized before the ConfigService.
// See more about this here: https://github.com/ngrx/platform/issues/174#issuecomment-373853594

import { CommonModule } from '@angular/common';
import {
  APP_BOOTSTRAP_LISTENER,
  Inject,
  InjectionToken,
  NgModule,
  Type,
} from '@angular/core';
import { EffectsModule, EffectSources } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../../environments/environment';
import { EffectsArray } from './effects';
import { metaReducers, reducers } from './reducers';

export const BOOTSTRAP_EFFECTS = new InjectionToken('Bootstrap Effects');

export function bootstrapEffects(effects: Type<any>[], sources: EffectSources) {
  return () => {
    effects.forEach((effect) => sources.addEffects(effect));
  };
}

export function createInstances(...instances: any[]) {
  return instances;
}

export function provideBootstrapEffects(effects: Type<any>[]) {
  return [
    effects,
    { provide: BOOTSTRAP_EFFECTS, deps: effects, useFactory: createInstances },
    {
      provide: APP_BOOTSTRAP_LISTENER,
      multi: true,
      useFactory: bootstrapEffects,
      deps: [[new Inject(BOOTSTRAP_EFFECTS)], EffectSources],
    },
  ];
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot({}),
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([]),
  ],
  providers: [provideBootstrapEffects(EffectsArray)],
})
export class AppStoreModule {}
