import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Project, ProjectType, UserManagerService } from '@sama/common';
import { SearchBox } from '@sama/ui-library/search-box';
import { DashboardTableDataLoaderService } from '../../../../../services/dashboard-table-data-loader.service';

@Component({
  selector: 'app-projects-table',
  templateUrl: './projects-table.component.html',
  styleUrls: ['./projects-table.component.scss'],
})
export class ProjectsTableComponent implements OnInit {
  constructor(
    public dataLoader: DashboardTableDataLoaderService,
    private userManagerService: UserManagerService,
  ) {}

  projectFilters: FormGroup = new FormGroup({
    projectNameColumn: new FormGroup({
      name: new FormControl<SearchBox | null>(null),
    }),
    projectClientCodeColumn: new FormGroup({
      clientCode: new FormControl<SearchBox | null>(null),
    }),
    projectGroupColumn: new FormGroup({
      projectGroupName: new FormControl<SearchBox | null>(null),
    }),
    projectStatusColumn: new FormGroup({
      statusOptions: new FormControl<ProjectType[]>(
        [ProjectType.DELIVERY, ProjectType.TRAINING, ProjectType.CERTIFICATION],
        { nonNullable: true },
      ),
      showDraftAndPausedProjects: new FormControl<boolean>(true, {
        nonNullable: true,
      }),
    }),
  });

  projectTypes = Object.values(ProjectType);

  slideText = 'Show draft and paused projects';

  @Input()
  rowClickCallback!: (project: Project) => void;

  ngOnInit() {
    this.userManagerService
      .isClientUser$()
      .subscribe((isClientUser: boolean) => {
        if (isClientUser) {
          this.statusFiltersFormGroup
            .get('statusOptions')
            ?.setValue([ProjectType.DELIVERY]);
        }
        this.statusFiltersFormGroup
          .get('showDraftAndPausedProjects')
          ?.setValue(false);
      });
  }

  generateCircleIconClass(project: Project) {
    const isOnHold = project.state !== 'active';
    const colorClassName = isOnHold
      ? 'state-hold'
      : `type__${project.projectType.toLowerCase()}`;

    return {
      'circle-icon': true,
      [colorClassName]: true,
    };
  }

  generateStateText(project: Project) {
    const inactive = project.state !== 'active' ? ` (${project.state})` : '';
    return project.projectType + inactive;
  }

  get nameFiltersFormGroup(): FormGroup {
    return this.projectFilters.get('projectNameColumn') as FormGroup;
  }

  get projectGroupFiltersFormGroup(): FormGroup {
    return this.projectFilters.get('projectGroupColumn') as FormGroup;
  }

  get statusFiltersFormGroup(): FormGroup {
    return this.projectFilters.get('projectStatusColumn') as FormGroup;
  }

  get clientCodeFiltersFormGroup(): FormGroup {
    return this.projectFilters.get('projectClientCodeColumn') as FormGroup;
  }
}
