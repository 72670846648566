import { of } from 'rxjs';

export class ReservationsServiceMock {
  findPage() {
    return of({
      items: [],
      page: 1,
      pageSize: 10,
      totalCount: 0,
      totalPages: 0,
    });
  }
  release() {
    return of(void 0);
  }
}
