import { HealthStatus } from '../health-status';

/**
 * Status probe response
 */
export class StatusResponse {
  status!: HealthStatus;
  services!: ServiceStatus[];

  constructor(init?: Partial<StatusResponse>) {
    Object.assign(this, init);
  }
}

/**
 * Health check response for a single service
 */
export class ServiceStatus {
  name!: string;
  status!: HealthStatus;

  constructor(init?: Partial<ServiceStatus>) {
    Object.assign(this, init);
  }
}
