import { Component, Inject, InjectionToken, OnInit } from '@angular/core';
import { AuthenticationService } from '@sama/ngx-components';

export const GO_LOCATION_TOKEN = new InjectionToken<Location>(
  'Window location object',
);

@Component({
  selector: 'app-create-project',
  template: '',
})
export class LogoutComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    @Inject(GO_LOCATION_TOKEN)
    private location: Location,
  ) {}

  ngOnInit(): void {
    // Log out and redirect to the home page so user should be prompted to log in again
    this.authenticationService.logout(`${this.location.origin}`);
  }
}
