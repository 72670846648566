import { Observable, of } from 'rxjs';

export class UserManagerServiceMock {
  getHubUserId$(): Observable<number> {
    return of(1);
  }

  isClientUser$(): Observable<boolean> {
    return of(false);
  }

  logout() {
    return;
  }
}
