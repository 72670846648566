export enum Icons {
  Accounts = 'accounts-logo',
  SamaCircle = 'sama-circle-logo',
  SamaApp = 'sama-app-logo',
  Sama = 'sama-logo',
  ErrorElephantPage = 'sama-elephant-error-logo',
  ErrorLionPage = 'sama-lion-error-logo',
  ProDemoProject = 'pro-demo-project',
  SignupSamaLogo = 'signup-sama-logo',
  DashboardLogo = 'dashboard-logo',
  ProjectsLogo = 'projects-logo',
  AvatarLogo = 'avatar-logo',
  DocumentationLogo = 'documentation-logo',
  NotificationsLogo = 'notifications-logo',
  SamaSLogo = 'sama-s-logo',
  Logout = 'logout',
  TasksBlankState = 'tasks-blank-state',
  HomePageDesign = 'home-page-design',
  IssueIcon = 'issue-icon',
}
