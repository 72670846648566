import {
  AuthenticationGuard,
  ErrorComponent,
  MaintenanceGuard,
} from '@sama/ngx-components';

import { Routes } from '@angular/router';
import { createAuthorizationGuard } from '@sama/angular-auth';
import { SamaForbiddenErrorComponent } from '@sama/ui-library/forbidden-error';
import { SamaNotFoundErrorComponent } from '@sama/ui-library/not-found-error';
import { AuthenticatedLayoutComponent } from './modules/base/layouts/authenticated/authenticated-layout.component';
import { EmptyLayoutComponent } from './modules/base/layouts/empty/empty-layout.component';
import { LogoutComponent } from './modules/base/logout/logout.component';
import { HealthComponent } from './modules/diagnostics/health/health.component';
import { StatusComponent } from './modules/diagnostics/status/status.component';
import { HomePageComponent } from './modules/home-page/home-page.component';

export const routes: Routes = [
  {
    path: '',
    // MaintenanceGuard MUST always be the first guard.
    canActivate: [MaintenanceGuard, AuthenticationGuard],
    component: AuthenticatedLayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/projects',
        pathMatch: 'full',
      },
      {
        path: 'projects',
        canActivate: [
          createAuthorizationGuard({
            action: 'project_list',
            resourceType: 'platform',
            getResourceId: () => 'hub',
          }),
        ],
        component: HomePageComponent,
        data: {
          // Used by events to provide the source name.
          title: 'My Projects',
          requiredPermissions: [],
        },
      },
      {
        path: 'projects/:id',
        loadChildren: () =>
          import('./modules/projects/projects.module').then(
            (m) => m.ProjectsModule,
          ),
      },
    ],
  },
  {
    path: 'callback',
    component: EmptyLayoutComponent,
  },
  {
    path: 'health',
    component: HealthComponent,
  },
  {
    path: 'status',
    component: StatusComponent,
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: 'not-found',
    component: SamaNotFoundErrorComponent,
  },
  {
    path: 'forbidden',
    component: SamaForbiddenErrorComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },

  // Wild Card Route for 404 request
  { path: '**', pathMatch: 'full', component: SamaNotFoundErrorComponent },
];
