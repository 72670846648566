import { APP_INITIALIZER, Injectable, Injector, Provider } from '@angular/core';
import { Store } from '@ngrx/store';
import { AccessTokenFields, EventNames, PortalConfig } from '@sama/common';
import { AuthenticationService, authFeatureKey } from '@sama/ngx-components';
import { EventTrackingService } from '@sama/ngx-events';
import { GoogleAnalyticsService } from '@sama/ngx-ga';
import jwt_decode from 'jwt-decode';
import { firstValueFrom, lastValueFrom, map, skipWhile } from 'rxjs';
import {
  experiment,
  store_in_warehouse,
} from '../shared/events-root-context-values';
import * as fromRoot from '../store/reducers';
import { AppcuesService } from './appcues.service';

declare global {
  var SAMPLING_CONFIG: PortalConfig;
  var PORTAL_CONFIG: PortalConfig;
}

@Injectable({ providedIn: 'root' })
export class AppInitializationService {
  private googleAnalyticsService!: GoogleAnalyticsService;
  private appcuesService!: AppcuesService;
  private eventTrackingService!: EventTrackingService;
  private authenticationService!: AuthenticationService;
  private store!: Store<fromRoot.State>;

  constructor(private injector: Injector) {}

  async run(): Promise<void> {
    this.googleAnalyticsService = this.injector.get(GoogleAnalyticsService);
    this.appcuesService = this.injector.get(AppcuesService);
    this.eventTrackingService = this.injector.get(EventTrackingService);
    this.authenticationService = this.injector.get(AuthenticationService);
    this.store = this.injector.get(Store<fromRoot.State>);

    globalThis.AUTHORIZATION_CONFIG = globalThis.PORTAL_CONFIG.authorization;

    const userId = await this.getTokenPayloadValue(AccessTokenFields.HubUserId);
    const clientId = await this.getTokenPayloadValue(
      AccessTokenFields.ClientId,
    );
    const auth0UserId = await this.getAuth0UserId();

    const { gaEnabled, gaTrackingId } =
      globalThis.PORTAL_CONFIG.googleAnalytics;
    if (gaEnabled === 'true') {
      this.googleAnalyticsService.setupGoogleAnalytics(gaTrackingId);
      this.googleAnalyticsService.subscribeRouterEvents();
    }

    this.appcuesService.identifyUser(userId);

    this.appcuesService.subscribeToRouterEvents();

    this.eventTrackingService.addRootContext({
      experiment,
      user_id: userId,
      client_id: clientId,
      store_in_warehouse,
      auth0_user_id: auth0UserId,
    });

    this.authenticationService.logInSub.subscribe((loginMethod) => {
      if (loginMethod) {
        this.eventTrackingService.track(EventNames.Login, {
          method: loginMethod,
        });
      }
    });

    this.authenticationService.silentLogInSub.subscribe((silentLogIn) => {
      if (silentLogIn) {
        this.eventTrackingService.track(EventNames.Login, {
          method: silentLogIn,
        });
      }
    });
  }

  private getTokenPayloadValue(key: string): Promise<any> {
    return lastValueFrom(
      this.authenticationService.getTokenSilently$().pipe(
        map((token) => {
          const decodedToken = this.decodeToken(token!);
          return decodedToken[key] ?? null;
        }),
      ),
    );
  }

  private getAuth0UserId(): Promise<string | undefined> {
    return firstValueFrom(
      this.store.select(authFeatureKey).pipe(
        skipWhile((auth0User) => !auth0User.user),
        map((auth0User) => {
          return auth0User.user?.sub;
        }),
      ),
    );
  }

  public decodeToken(token: string): any {
    return jwt_decode(token);
  }
}

export const appInitializationProvider: Provider = {
  provide: APP_INITIALIZER,
  deps: [AppInitializationService],
  useFactory: (appInitializationService: AppInitializationService) =>
    appInitializationService.run(),
  multi: true,
};
