import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TableModule } from '@sama/ngx-components';
import { SamaTableModule } from '@sama/ui-library/table';
import { SectionModule } from '../base/section/section.module';

import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HomePageComponent } from './home-page.component';
import { ProjectsListComponent } from './sections/projects-list/projects-list.component';
import { ProjectsTableComponent } from './sections/projects-list/projects-table/projects-table.component';

@NgModule({
  declarations: [
    ProjectsTableComponent,
    ProjectsListComponent,
    HomePageComponent,
  ],
  exports: [HomePageComponent],
  imports: [
    CommonModule,
    TableModule,
    SectionModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatDialogModule,
    MatSnackBarModule,
    SamaTableModule,
    MatListModule,
    MatSlideToggleModule,
  ],
})
export class HomePageModule {}
