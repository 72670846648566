import { AsyncFactoryFn, TestElement } from '@angular/cdk/testing';
import { MatButtonHarness } from '@angular/material/button/testing';
import { BaseDialogHarness } from '../base-dialog/base-dialog.harness';

export class InfoDialogHarness extends BaseDialogHarness {
  private getOkButtonHarness: AsyncFactoryFn<MatButtonHarness> =
    this.locatorFor(MatButtonHarness.with({ text: 'OK' }));

  private getMessageElement: AsyncFactoryFn<TestElement> =
    this.locatorFor('div[body] > p');

  public getDetailsElement: AsyncFactoryFn<TestElement[]> =
    this.locatorForAll('li');

  public async clickOkButton(): Promise<void> {
    const okButton = await this.getOkButtonHarness();
    return okButton.click();
  }

  public async getOkText(): Promise<string> {
    const okButton = await this.getOkButtonHarness();
    return okButton.getText();
  }

  public async getMessage(): Promise<string> {
    return await (await this.getMessageElement()).text();
  }

  public async click(buttonText: string): Promise<void> {
    const button = await this.locatorFor(
      MatButtonHarness.with({ text: buttonText }),
    )();
    return button.click();
  }
  public async toggleViewDetails(): Promise<void> {
    const button = await this.locatorFor(
      MatButtonHarness.with({ text: 'expand_more' }),
    )();
    return button.click();
  }
}
