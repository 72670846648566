import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SecondLevelConfig } from '../shared/second-level.config';

@Injectable({
  providedIn: 'root',
})
export class SecondLevelService {
  private config = new BehaviorSubject<SecondLevelConfig>({
    title: '',
    tabs: [],
    visible: false,
    navigateBack: {
      url: '',
      label: '',
    },
  });
  config$ = this.config.asObservable();

  updateConfig(config: Partial<SecondLevelConfig>) {
    const currentConfig = this.config.getValue();
    this.config.next({ ...currentConfig, ...config });
  }
}
