import { ErrorHandler, Injectable, InjectionToken } from '@angular/core';
import * as Rollbar from 'rollbar';

const supportedRollBarEnvironments = [
  'local',
  'development',
  'staging',
  'production',
];

export function isRollbarEnvironmentSupported(
  rollbarEnvironment: string,
): boolean {
  return supportedRollBarEnvironments.includes(rollbarEnvironment);
}

const ignoredMessages = [
  // Ignore Auth0's SDK error
  'Login required',
];

export const rollbarConfig: Rollbar.Configuration = {
  captureUncaught: true,
  captureUnhandledRejections: true,
  ignoredMessages,
};

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  rollbar?: Rollbar;

  constructor() {}

  handleError(err: any): void {
    // If rollbar it is not initialized (this can happen at app start when providing app initializers), then
    // create a new instance of Rollbar
    if (this.rollbar === undefined) {
      this.rollbar = RollbarCreator.creator();
    }
    console.error(err.originalError || err);
    this.rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory(): Rollbar {
  const rollbarEnvironment: string = PORTAL_CONFIG.rollbar.environment;
  if (isRollbarEnvironmentSupported(rollbarEnvironment)) {
    rollbarConfig.environment = rollbarEnvironment;
    rollbarConfig.accessToken = PORTAL_CONFIG.rollbar.accessToken;
    return new Rollbar(rollbarConfig);
  } else {
    throw new Error(`Rollbar Environment not supported: ${rollbarEnvironment}`);
  }
}

// Class so we can mock rollbar.
export class RollbarCreator {
  static creator = rollbarFactory;
}

export const RollbarService = new InjectionToken<Rollbar>('RollbarService');
