import { KJUR } from 'jsrsasign';
import {
  AccessTokenFields,
  JwtPayload,
} from '../models/auth0/access-token-constants';

const defaultPayload = (): JwtPayload => {
  const payload: JwtPayload = {
    id: '123',
    type: 'client',
    sub: 'auth|123',
    expiresIn: '1h',
    issuer: 'localhost',
    scope: '',
    permissions: [],
    audience: 'http://localhost',
  };
  payload[AccessTokenFields.ClientId] = 1;
  payload[AccessTokenFields.HubUserId] = 1;

  return payload;
};

export function generateJwt(jwtPayload: JwtPayload): string {
  const headers = JSON.stringify({ alg: 'HS256', typ: 'JWT' });
  const payload = JSON.stringify({ ...defaultPayload(), ...jwtPayload });

  return KJUR.jws.JWS.sign('HS256', headers, payload, 'S3cr3tK3y*');
}
