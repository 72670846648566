import { IssueTypeEnum } from './issue-type.enum';

export class IssueType {
  type!: IssueTypeEnum;
  name!: string;

  constructor(init?: Partial<IssueType>) {
    Object.assign(this, init);
  }
}
