import { Component, OnInit } from '@angular/core';
import { StatusResponse } from './status.response';
import { StatusService } from './status.service';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
})
export class StatusComponent implements OnInit {
  status!: StatusResponse;

  constructor(private service: StatusService) {}

  ngOnInit(): void {
    this.service.checkStatus().then((s) => (this.status = s));
  }
}
