import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HealthComponent } from './health/health.component';
import { StatusComponent } from './status/status.component';

@NgModule({
  declarations: [HealthComponent, StatusComponent],
  imports: [CommonModule],
  exports: [HealthComponent],
})
export class DiagnosticsModule {}
