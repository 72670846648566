import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { HealthCheck } from '../../health-check.interface';
import { HealthStatus } from '../../health-status';
@Injectable({
  providedIn: 'root',
})
export class BackendHealthCheckService implements HealthCheck {
  protected readonly backendUrl: string;
  currentStatus!: Observable<HealthStatus>;

  constructor(protected http: HttpClient) {
    this.backendUrl = `${PORTAL_CONFIG.backendBaseUrl}/status`;
  }

  readonly name = 'BE';

  /**
   * Checks BE status
   */
  async checkStatus(): Promise<HealthStatus> {
    return lastValueFrom(this.http.get<any>(this.backendUrl))
      .then(() => {
        return HealthStatus.HEALTHY;
      })
      .catch(() => {
        return HealthStatus.UNHEALTHY;
      });
  }
}
