export class ProjectOutput {
  id!: number;
  name!: string;
  projectId!: number;
  createdAt!: string;
  updatedAt!: string;
  type!: string;
  required!: boolean;
  gradeGold!: boolean;
  archiveToken!: string | null;
  meta!: object;
  hideFromDeliveries!: boolean;
  ordinal!: number;

  constructor(init?: Partial<ProjectOutput>) {
    Object.assign(this, init);
  }
}
