import { Observable, of } from 'rxjs';
import { InternalUser } from '../../models/user/internal-user.type';
import { UserInfo, UserInfoMap } from '../../models/user/users.types';

export class UsersServiceMock {
  constructor() {}

  getUserInfo(userIds: number[]): Observable<UserInfoMap> {
    return of({
      1: { id: 1, fullName: 'user1' },
      2: { id: 2, fullName: 'user2' },
    });
  }

  getUsersInfoByTeams(
    teamIds: number[],
    search?: string,
    sort?: string,
    page?: number,
    limit?: number,
  ): Observable<UserInfo[]> {
    return of([
      { id: 1, fullName: 'user1' },
      { id: 2, fullName: 'user2' },
    ] as UserInfo[]);
  }

  getInternalUserInfo(userId: number): Observable<InternalUser> {
    return of({} as InternalUser);
  }

  getMyInfo(): Observable<InternalUser> {
    return of({} as InternalUser);
  }
}
