import { IssueAttachment } from './issue-attachment.model';

export class IssueComment {
  id!: string;
  issueId!: string;
  text!: string;
  isSolution!: boolean;
  createdBy!: number;
  createdAt!: string;
  updatedBy!: number | null;
  updatedAt!: string | null;
  attachments?: IssueAttachment[];
  userFullName!: string;

  public constructor(init?: Partial<IssueComment>) {
    Object.assign(this, init);
  }
}
