import { Injectable } from '@angular/core';
import { NavigationOptions, NavigationType } from '@sama/ui-library';

@Injectable({
  providedIn: 'root',
})
export class HelpItemsService {
  getHelpItems(): NavigationOptions[] {
    return [
      {
        label: 'Documentation',
        menuItems: [
          {
            label: 'Platform',
            url: 'https://docs.sama.com',
            type: NavigationType.REDIRECT_NAVIGATION,
          },
          {
            label: 'API & CLI',
            url: 'https://samakb.readme.io',
            type: NavigationType.REDIRECT_NAVIGATION,
          },
        ],
        type: NavigationType.NESTED_MENU_NAVIGATION,
      },
    ];
  }
}
