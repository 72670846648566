import { Entity } from '@sama/ngx-components';
import { Client } from '../client/hub-client.model';
import { ProjectGroup } from './project-group.model';
import { ProjectState } from './project-state.enum';
import { ProjectType } from './project-type.enum';

export class Project implements Entity {
  id!: number;
  name!: string;
  createdAt!: string;
  updatedAt!: string;
  visualizationEnabled!: boolean;
  state!: ProjectState;
  projectType!: ProjectType;
  projectGroupId!: number;
  isArchived!: boolean;
  clientId!: number;
  projectGroup?: ProjectGroup;
  client?: Client;
  useAnnotationStore!: boolean;

  public constructor(init?: Partial<Project>) {
    Object.assign(this, init);
  }
}
