import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Project } from '@sama/common';
import { ColumnDefinition } from '@sama/ngx-components';
import { EventTrackingService } from '@sama/ngx-events';
import { ProjectsHomePageHelperService } from '../../helpers/projects-home-page-helper.service';

@Component({
  selector: 'app-projects-list',
  templateUrl: './projects-list.component.html',
  styleUrls: ['./projects-list.component.scss'],
  providers: [EventTrackingService, ProjectsHomePageHelperService],
})
export class ProjectsListComponent implements OnInit {
  columns!: ColumnDefinition[];

  navigateToProjectDashboard!: (project: Project) => void;

  constructor(
    public dialog: MatDialog,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.navigateToProjectDashboard = (project: Project) => {
      this.router.navigateByUrl(`/projects/${project.id}/dashboard`);
    };
  }
}
