export type UserInfo = {
  id: number;
  fullName: string;
};
export type UserInfoMap = {
  [key: string]: UserInfo;
};
export type GetUserResponseDataType = {
  data: UserInfo[];
};
export type GetInternalUsersInfoResponseDataType = {
  data: UserInfo[];
};
