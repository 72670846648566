import { PagedQueryOptions, QueryFilter } from '@sama/ngx-components';
import { DataLoader, TableSettings } from '@sama/ui-library/table';
import { Observable, map } from 'rxjs';

import { Injectable } from '@angular/core';
import { Project, ProjectsService } from '@sama/common';
import { LoadDataResult } from '@sama/ui-library';

@Injectable({
  providedIn: 'root',
})
export class DashboardTableDataLoaderService extends DataLoader<Project> {
  constructor(private projectsService: ProjectsService) {
    super();
  }

  private filterFormatFnByColumn: Record<string, any> = {
    projectNameColumn: (filtersQuery: QueryFilter[], formValues: any) => {
      filtersQuery.push({
        name: { $contL: formValues.name.searchText },
      });
    },
    projectClientCodeColumn: (filtersQuery: QueryFilter[], formValues: any) => {
      filtersQuery.push({
        clientCode: { $contL: formValues.clientCode.searchText },
      });
    },
    projectGroupColumn: (filtersQuery: QueryFilter[], formValues: any) => {
      filtersQuery.push({
        'projectGroup.name': {
          $contL: formValues.projectGroupName.searchText,
        },
      });
    },
    projectStatusColumn: (filtersQuery: QueryFilter[], formValues: any) => {
      if (formValues?.statusOptions?.length) {
        filtersQuery.push({
          projectType: {
            $in: formValues.statusOptions,
          },
        });
      }

      if (!formValues?.showDraftAndPausedProjects) {
        filtersQuery.push({
          state: { $contL: 'active' },
        });
      }
    },
  };

  loadData(
    settings: TableSettings,
    props?: any,
  ): Observable<LoadDataResult<Project>> {
    const pagedOptions = new PagedQueryOptions({
      page: {
        index: settings.pageIndex,
        size: settings.pageSize,
      },
      filters: this.formatFilters(settings.filters),
      joins: ['projectGroup', 'client'],
    });

    return this.projectsService.findPage(pagedOptions).pipe(
      map((result) => {
        return {
          numResults: result.total,
          data: result.data,
        };
      }),
    );
  }

  formatFilters(filters: any): QueryFilter {
    const filtersQuery: QueryFilter[] = [];
    Object.entries(filters).forEach(([column, formValues]) => {
      const formatFn = this.filterFormatFnByColumn[column];
      if (formatFn) formatFn(filtersQuery, formValues);
    });
    return { $and: filtersQuery };
  }
}
