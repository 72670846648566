import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  BaseService,
  LoggingService,
  MessagingService,
} from '@sama/ngx-components';
import * as Rollbar from 'rollbar';
import { Observable, catchError, map } from 'rxjs';
import { InternalUser } from '../models/user/internal-user.type';
import {
  GetInternalUsersInfoResponseDataType,
  GetUserResponseDataType,
  UserInfo,
  UserInfoMap,
} from '../models/user/users.types';
import { RollbarService } from './rollbar-error-handler.service';
@Injectable({
  providedIn: 'root',
})
export class UsersService extends BaseService {
  private readonly baseApiUrl: string;
  constructor(
    @Inject(RollbarService)
    private rollbar: Rollbar,
    private http: HttpClient,
    messagingService: MessagingService,
    loggingService: LoggingService,
  ) {
    super(messagingService, loggingService);

    this.baseApiUrl = `${PORTAL_CONFIG.accountsBackendUrl}`;
  }
  getUserInfo(userIds: number[]): Observable<UserInfoMap> {
    if (!userIds.length) {
      // Return an empty observable if there are no user ids. Otherwise, the API call will fail.
      return new Observable((observer) => {
        observer.next({});
        observer.complete();
      });
    }
    return this.http
      .get<GetUserResponseDataType>(
        `${this.baseApiUrl}/api/users?filter=id||$in||${userIds.join(
          ',',
        )}&select=fullName`,
      )
      .pipe(
        map((response) => {
          const map: UserInfoMap = {};
          response.data.forEach(({ id, fullName }) => {
            map[id] = { id, fullName };
          });
          return map;
        }),
        catchError((error) => {
          this.rollbar.error(
            new Error('Failed fetching user information').stack,
          );
          return new Observable<UserInfoMap>((observer) => {
            observer.next({});
            observer.complete();
          });
        }),
      );
  }

  getUsersInfoByTeams(
    teamIds: number[],
    search?: string,
    sort?: string,
    page?: number,
    limit?: number,
  ): Observable<UserInfo[]> {
    let url = `${this.baseApiUrl}/api/users`;

    if (search && search.length) {
      url += `?s={"$and":[{"fullName":{"$contL":"${search}"}},{"teamId":{"$in":[${teamIds.join(',')}]}}]}`;
    } else {
      url += `?filter=teamId||$in||${teamIds.join(',')}`;
    }

    if (sort && sort.length) {
      url += `&sort=${sort}`;
    }

    if (page && page > 0) {
      url += `&page=${page}`;
    }

    if (limit && limit > 0) {
      url += `&limit=${limit}`;
    }

    return this.http
      .get<GetInternalUsersInfoResponseDataType>(`${url}&select=id,fullName`)
      .pipe(
        map((response) => {
          return response.data;
        }),
        catchError((error) => {
          this.rollbar.error(
            new Error('Failed to get internal users info').stack,
          );
          return this.handleError('getUsersInfoByTeams')(error);
        }),
      );
  }

  getInternalUserInfo(userId: number): Observable<InternalUser> {
    return this.http
      .get<InternalUser>(`${this.baseApiUrl}/api/teams/users/${userId}`)
      .pipe(
        catchError((error) => {
          this.rollbar.error(
            new Error('Failed to get internal user info').stack,
          );
          return this.handleError('getInternalUserInfo')(error);
        }),
      );
  }
}
