import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppcuesLaunchpadConfig } from '../shared/appcues-launchpad-config';

// To be able to extend the Window interface and suppress type errors
declare global {
  interface Window {
    Appcues: {
      identify: (id: any) => {};
      page(): () => {};
      track(
        eventName: string,
        data?: object,
      ): (name: string, data?: object) => {};
      loadLaunchpad(launchpadId: string, position?: object): () => {};
    };
  }
}

@Injectable({
  providedIn: 'root',
})
export class AppcuesService implements OnDestroy {
  constructor(
    private router: Router,
    private appCuesRef: Window,
  ) {}
  private subscriptions: Subscription[] = [];

  // Identity the user for Appcues
  public identifyUser(userId: any): void {
    this.appCuesRef.Appcues && this.appCuesRef.Appcues.identify(userId);
  }

  // let appcues know that there has been a page change
  public subscribeToRouterEvents() {
    const routerEventsSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.appCuesRef.Appcues && this.appCuesRef.Appcues.page();
      }
    });
    this.subscriptions.push(routerEventsSubscription);
  }

  public track(eventName: string, data?: object) {
    this.appCuesRef.Appcues && this.appCuesRef.Appcues.track(eventName, data);
  }

  public loadLaunchpad(
    launchpadId: string,
    config?: AppcuesLaunchpadConfig,
  ): void {
    this.appCuesRef.Appcues &&
      this.appCuesRef.Appcues.loadLaunchpad(launchpadId, config);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
