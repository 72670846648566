import {
  Entity,
  FindAllResult,
  FindPageResult,
  PagedQueryOptions,
  QueryOptions,
} from '@sama/ngx-components';
import { Observable, of } from 'rxjs';

export abstract class BaseCrudServiceMock<T extends Entity> {
  constructor(private data: T[]) {}

  findPage(options?: PagedQueryOptions): Observable<FindPageResult<T>> {
    const page = Number.isInteger(options?.page?.index)
      ? options!.page!.index
      : 0;
    const pageSize = options?.page?.size || 200;

    const pageData = this.data.slice(
      page * pageSize,
      page * pageSize + pageSize,
    );

    const result: FindPageResult<T> = {
      data: pageData,
      count: pageData.length,
      total: this.data.length,
      page,
      pageCount: Math.ceil(this.data.length / pageSize),
    };
    return of(result);
  }

  findAll(options?: QueryOptions): Observable<FindAllResult<T>> {
    const result: FindAllResult<T> = {
      data: this.data,
    };
    return of(result);
  }

  findById(id: number, options?: QueryOptions): Observable<T> {
    return of();
  }

  findOne(options: QueryOptions): Observable<T> {
    return of();
  }

  update(entity: T, options?: QueryOptions): Observable<T> {
    return of();
  }
}
