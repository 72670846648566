export interface ProjectStats {
  tasks: ProjectTasks;
}

export interface ProjectTasks {
  lastSubmittedAt: string;
  lastUpdated: string;
  count: number;
  states: {
    new: number;
    inProgress: number;
    completed: number;
    delivered: number;
    rejected: number;
    approved: number;
    acknowledged: number;
  };
}
