<sama-table
  class="projects-table"
  [dataLoader]="dataLoader"
  [isRowClickable]="true"
  [showClickableArrow]="true"
  (onClickedRow)="rowClickCallback($event)"
>
  <ng-container ngProjectAs="[toolbarSlot]">
    <sama-table-toolbar [showRowsSelectionLabel]="false">
      <ng-container ngProjectAs="[leftSlot]">
        <p class="projects-table__subtitle">Select a project to get started</p>
      </ng-container>
    </sama-table-toolbar>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>
      <sama-header-cell [label]="'Project name'">
        <sama-filter-overlay
          [columnLabel]="'projectNameColumn'"
          [formGroup]="nameFiltersFormGroup"
        >
          <sama-search-box
            formControlName="name"
            [required]="true"
          ></sama-search-box>
        </sama-filter-overlay>
      </sama-header-cell>
    </th>
    <td
      class="projects-table__cell mat-body-01"
      mat-cell
      *matCellDef="let element"
    >
      {{ element.name }}
    </td>
  </ng-container>

  <ng-container matColumnDef="clientCode">
    <th mat-header-cell *matHeaderCellDef>
      <sama-header-cell [label]="'Client code'">
        <sama-filter-overlay
          [columnLabel]="'projectClientCodeColumn'"
          [formGroup]="clientCodeFiltersFormGroup"
        >
          <sama-search-box
            formControlName="clientCode"
            [required]="true"
          ></sama-search-box>
        </sama-filter-overlay>
      </sama-header-cell>
    </th>
    <td
      class="projects-table__cell mat-body-01"
      mat-cell
      *matCellDef="let element"
    >
      {{ element.client?.code || '' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="projectGroupName">
    <th mat-header-cell *matHeaderCellDef>
      <sama-header-cell [label]="'Project group'">
        <sama-filter-overlay
          [columnLabel]="'projectGroupColumn'"
          [formGroup]="projectGroupFiltersFormGroup"
        >
          <sama-search-box
            formControlName="projectGroupName"
            [required]="true"
          ></sama-search-box>
        </sama-filter-overlay>
      </sama-header-cell>
    </th>
    <td
      class="projects-table__cell mat-body-01"
      mat-cell
      *matCellDef="let element"
    >
      {{ element.projectGroup?.name || '' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>
      <sama-header-cell [label]="'Status'">
        <sama-filter-overlay
          [columnLabel]="'projectStatusColumn'"
          [formGroup]="statusFiltersFormGroup"
          [triggerOnInit]="true"
        >
          <mat-selection-list formControlName="statusOptions" [required]="true">
            <mat-list-option
              *ngFor="let type of projectTypes"
              color="primary"
              [value]="type"
              [checkboxPosition]="'before'"
              class="mat-subtitle-2"
            >
              {{ type }}
            </mat-list-option>
            <div class="projects-table__filter-underline"></div>
          </mat-selection-list>

          <div class="projects-table__slider">
            <span>{{ slideText }}</span>
            <mat-slide-toggle
              color="primary"
              formControlName="showDraftAndPausedProjects"
            >
            </mat-slide-toggle>
          </div>
        </sama-filter-overlay>
      </sama-header-cell>
    </th>
    <td
      class="projects-table__cell mat-body-01"
      mat-cell
      *matCellDef="let element"
    >
      <div class="status-cell">
        <mat-icon [ngClass]="generateCircleIconClass(element)">
          trip_origin
        </mat-icon>
        <span>
          {{ generateStateText(element) | titlecase }}
        </span>
      </div>
    </td>
  </ng-container>
</sama-table>
