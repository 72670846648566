import { Component, OnInit } from '@angular/core';
import { Icons } from '@sama/common';
import { IconsService } from '@sama/ngx-components';
import { AppInitializationService } from './services/app-initialization.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Sama Portal';

  constructor(
    private iconService: IconsService,
    private init: AppInitializationService,
  ) {
    this.iconService.registerIcons('assets/icons', Icons);
  }

  /*
   * Init method
   */
  ngOnInit() {
    this.init.run();
  }
}
