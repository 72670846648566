import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  CachedBaseCrudService,
  ConfigService,
  FindAllResult,
  FindPageResult,
  LoggingService,
  MessagingService,
  PagedQueryOptions,
  QueryOptions,
} from '@sama/ngx-components';
import * as Rollbar from 'rollbar';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ProjectStats } from '../models/project/project-stats.interface';
import { Project } from '../models/project/project.model';
import { SAMA_GO_API_VERSION } from '../shared/api-versions';
import { RollbarService } from './rollbar-error-handler.service';

export interface PaginatedProjectsResponse {
  projects: Project[];
  total: number;
  count: number;
  page: number;
  pageCount: number;
}

@Injectable()
export class ProjectsService extends CachedBaseCrudService<Project> {
  constructor(
    @Inject(RollbarService)
    private rollbar: Rollbar,
    configService: ConfigService,
    http: HttpClient,
    messagingService: MessagingService,
    loggingService: LoggingService,
  ) {
    super(
      Project,
      `/${SAMA_GO_API_VERSION}/projects`,
      configService,
      http,
      messagingService,
      loggingService,
    );
  }

  findById(projectId: number, options?: QueryOptions): Observable<Project> {
    return super.findById(projectId, options).pipe(
      catchError((error) => {
        this.rollbar.error(new Error('Failed fetching project').stack);
        return this.handleError('findById')(error);
      }),
    );
  }

  findPage(options?: PagedQueryOptions): Observable<FindPageResult<Project>> {
    const optionsString = options ? `?${options?.toQueryString()}` : '';
    const url = `${this.baseApiUrl}${optionsString}`;

    return this.http.get<FindPageResult<Project>>(url).pipe(
      switchMap((projectsPage) => {
        return of(
          new FindPageResult<Project>({
            ...projectsPage,
            data: projectsPage.data.map((p) => new Project(p)),
          }),
        );
      }),
      catchError((error) => {
        this.rollbar.error(new Error('Failed fetching projects').stack);
        return this.handleError('findPage')(error);
      }),
    );
  }

  findAll(): Observable<FindAllResult<Project>> {
    return this.http.get<FindAllResult<Project>>(this.baseApiUrl).pipe(
      catchError((error) => {
        this.rollbar.error(new Error('Failed fetching projects').stack);
        return this.handleError('findAll')(error);
      }),
    );
  }

  getProjectStats(
    projectId: number,
    { from }: { from?: Date } = {},
  ): Observable<ProjectStats> {
    const url = `${this.baseApiUrl}/${projectId}/stats`;
    const params: Record<string, string> = {};
    if (from) {
      params.from = from.toISOString();
    }
    return this.http.get<ProjectStats>(url, { params }).pipe(
      catchError((error) => {
        this.rollbar.error(
          new Error('Failed getting stats from project').stack,
        );
        return this.handleError('getProjectStats')(error);
      }),
    );
  }
}
