export class Attachment {
  id!: string;
  filename!: string;
  type!: string;
  s3ObjectKey!: string;
  preSignedUrl!: string;

  public constructor(init?: Partial<Attachment>) {
    Object.assign(this, init);
  }
}
