import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  BaseService,
  FindPageResult,
  LoggingService,
  MessagingService,
  PagedQueryOptions,
  QueryOptions,
} from '@sama/ngx-components';
import * as Rollbar from 'rollbar';
import { Observable, catchError, of, switchMap, tap } from 'rxjs';
import { IssueComment } from '../models/issue/issue-comment.model';
import { IssuesStats } from '../models/issue/issue-stats.interface';
import { Issue } from '../models/issue/issue.model';
import { CLIENT_CALIBRATION_API_VERSION } from '../shared/api-versions';
import { RollbarService } from './rollbar-error-handler.service';

@Injectable()
export class IssuesService extends BaseService {
  private baseApiUrl: string;

  constructor(
    @Inject(RollbarService)
    private rollbar: Rollbar,
    private http: HttpClient,
    messagingService: MessagingService,
    loggingService: LoggingService,
  ) {
    super(messagingService, loggingService);
    this.baseApiUrl = `${PORTAL_CONFIG.clientCalibrationUrl}/api/${CLIENT_CALIBRATION_API_VERSION}/projects`;
  }

  findPage(
    projectId: number,
    options?: PagedQueryOptions,
  ): Observable<FindPageResult<Issue>> {
    const optionsString = options ? `?${options?.toQueryString()}` : '';
    const url = `${this.baseApiUrl}/${projectId}/issues${optionsString}`;

    return this.http.get<FindPageResult<Issue>>(url).pipe(
      tap((_) => this.logSuccess(`fetched entities`)),
      switchMap((issuesPage) => {
        return of({
          ...issuesPage,
          data: issuesPage.data.map((i) => new Issue(i)),
        });
      }),
      catchError((error) => {
        this.rollbar.error(new Error('Failed fetching issues').stack);
        return this.handleError('findPage')(error);
      }),
    );
  }

  getOne(
    projectId: number,
    issueId: string,
    options?: PagedQueryOptions,
  ): Observable<Issue | null> {
    const optionsString = options ? `?${options?.toQueryString()}` : '';
    const url = `${this.baseApiUrl}/${projectId}/issues/${issueId}${optionsString}`;
    return this.http.get<Issue>(url).pipe(
      tap((_) => this.logSuccess(`fetched issue`)),
      switchMap((issue) => {
        return of(issue);
      }),
      catchError((error) => {
        const issueNotFoundError = 'Issue not found';
        const invalidIssueIdError = 'Invalid param id. UUID string expected';

        if (
          error.error.message === issueNotFoundError ||
          error.error.message === invalidIssueIdError
        ) {
          return of(null);
        }

        this.rollbar.error(new Error(`Failed fetching issue ${issueId}`).stack);
        return this.handleError('getOne')(error);
      }),
    );
  }

  deleteOne(
    issueId: string,
    taskId: string,
    projectId: number,
  ): Observable<void> {
    const url = `${this.baseApiUrl}/${projectId}/tasks/${taskId}/issues/${issueId}`;
    return this.http.delete<void>(url).pipe(
      tap(() => this.logSuccess(`Issue deleted successfully.`)),
      catchError((error) => {
        this.rollbar.error(new Error(`Failed deleting issue ${issueId}`).stack);
        return this.handleError('deleteOne')(error);
      }),
    );
  }

  getIssuesStats(projectId: number): Observable<IssuesStats> {
    const url = `${this.baseApiUrl}/${projectId}/issues/stats`;
    return this.http.get<IssuesStats>(url).pipe(
      catchError((error) => {
        this.rollbar.error(new Error('Failed getting stats from issues').stack);
        return this.handleError('getIssuesStats')(error);
      }),
    );
  }

  updateOne(
    issueId: string,
    projectId: number,
    taskId: string,
    issue: Partial<Issue>,
    options?: QueryOptions,
  ): Observable<Issue> {
    const optionsString = options ? `?${options?.toQueryString()}` : '';
    const url =
      `${this.baseApiUrl}/${projectId}/tasks/${taskId}/` +
      `issues/${issueId}${optionsString}`;
    return this.http.patch<Issue>(url, issue).pipe(
      catchError((error) => {
        this.rollbar.error(new Error('Failed updating issue').stack);
        return this.handleError('updateOne')(error);
      }),
    );
  }

  getComments(
    projectId: number,
    taskId: string,
    issueId: string,
    options?: QueryOptions,
  ): Observable<FindPageResult<IssueComment>> {
    const optionsString = options ? `?${options?.toQueryString()}` : '';
    const url =
      `${this.baseApiUrl}/${projectId}/tasks/${taskId}/` +
      `issues/${issueId}/comments${optionsString}`;
    return this.http.get<FindPageResult<IssueComment>>(url).pipe(
      catchError((error) => {
        this.rollbar.error(new Error('Failed to get issue comments').stack);
        return this.handleError('getComments')(error);
      }),
    );
  }
}
