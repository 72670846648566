import { Injectable } from '@angular/core';
import { HealthStatus } from '../health-status';

@Injectable({
  providedIn: 'root',
})
export class HealthService {
  public async checkStatus(): Promise<HealthStatus> {
    return HealthStatus.HEALTHY;
  }
}
