import { Injectable } from '@angular/core';
import { HealthCheck } from '../health-check.interface';
import { HealthStatus } from '../health-status';
import { BackendHealthCheckService } from './backend/backend-health-check.service';
import { ServiceStatus, StatusResponse } from './status.response';
@Injectable({
  providedIn: 'root',
})
export class StatusService {
  private healthChecks: HealthCheck[];

  constructor(backendHealthChecker: BackendHealthCheckService) {
    this.healthChecks = [backendHealthChecker];
  }

  public async checkStatus(): Promise<StatusResponse> {
    // Run all health checks...
    const results: ServiceStatus[] = await Promise.all(
      this.healthChecks.map((hc) =>
        hc
          .checkStatus()
          .then((status) => new ServiceStatus({ name: hc.name, status })),
      ),
    );

    // Aggregates status: Overall status is healthy if all services are healthy
    const status: HealthStatus = results.every(
      (hc) => hc.status === HealthStatus.HEALTHY,
    )
      ? HealthStatus.HEALTHY
      : HealthStatus.UNHEALTHY;

    return new StatusResponse({ status, services: results });
  }
}
