import { Observable, of } from 'rxjs';
import { ProjectStep } from '../../models/project/project-step.model';

export class SamaProjectsServiceMock {
  getProjectSteps(
    _projectId: number,
    _withAllocations: boolean,
  ): Observable<ProjectStep[]> {
    return of([]);
  }
}
