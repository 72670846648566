import { Component } from '@angular/core';
import { Icons } from '@sama/common';

@Component({
  selector: 'app-projects-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent {
  designIcon = Icons.HomePageDesign;
}
