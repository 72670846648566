import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import {
  AuthState,
  authFeatureKey,
  reducerStatements,
} from '@sama/ngx-components';
import {
  ProjectsState,
  projectsFeatureKey,
  projectsReducer,
} from '../projects/projects.reducer';

export interface State {
  [authFeatureKey]: AuthState;
  [projectsFeatureKey]: ProjectsState;
}

export const reducers: ActionReducerMap<State> = {
  [authFeatureKey]: reducerStatements,
  [projectsFeatureKey]: projectsReducer,
};

export const metaReducers: MetaReducer<State>[] = [];
