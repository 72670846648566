export class ProjectStepAllocation {
  id!: number;
  teamId!: number;
  quota?: number;

  constructor(init?: Partial<ProjectStepAllocation>) {
    Object.assign(this, init);
  }
}

export class ProjectStep {
  id!: number;
  name!: string;
  ordinal!: number;
  stepType!: string;
  reservationExpirationSeconds!: number;
  allocations?: ProjectStepAllocation[];

  constructor(init?: Partial<ProjectStep>) {
    Object.assign(this, init);
  }
}
