import { FindPageResult } from '@sama/ngx-components';
import { IssueAttachment } from '../models/issue/issue-attachment.model';
import { IssueComment } from '../models/issue/issue-comment.model';
import { IssueStatus } from '../models/issue/issue-status.enum';
import { IssueTypeEnum } from '../models/issue/issue-type.enum';
import { Issue } from '../models/issue/issue.model';
import { ProjectState } from '../models/project/project-state.enum';
import { ProjectStats } from '../models/project/project-stats.interface';
import { ProjectType } from '../models/project/project-type.enum';
import { Project } from '../models/project/project.model';
import { InternalUser } from '../models/user/internal-user.type';

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

export function createIssue(issue?: Partial<Issue>): Issue {
  return new Issue({
    id: '11111111-1111-1111-1111-111111111111',
    projectId: 1,
    taskId: '60a66d69280bde1234567890',
    type: IssueTypeEnum.Other,
    status: IssueStatus.New,
    title: 'Test',
    description: 'This is a description.',
    tags: ['tag1', 'tag2', 'tag3'],
    parkTasks: true,
    location: {},
    createdBy: 1,
    createdAt: '2022-01-17T03:24:00.104Z',
    updatedBy: 2,
    updatedAt: null,
    closedBy: 3,
    closedAt: null,
    issueType: { type: IssueTypeEnum.Other, name: 'Other' },
    attachments: [
      {
        id: '11111111-1111-1111-1111-111111111111',
        filename: 'Screenshot.png',
        type: 'image/png',
        s3ObjectKey: 'Screenshot.png',
        issueId: '11111111-1111-1111-1111-111111111111',
        preSignedUrl: 'https://test-image.jpg',
      },
    ],
    userFullName: 'John Doe',
    ...issue,
  });
}

export function createNAmountOfIssues(amount: number): Issue[] {
  const issues: Issue[] = [];
  for (let i = 1; i <= amount; i++) {
    issues.push(
      createIssue({
        id: `11111111-1111-1111-1111-111111111111${i}`,
      }),
    );
  }
  return issues;
}

export function createNAmountOfProjects(amount: number): Project[] {
  const projects: Project[] = [];
  for (let i = 1; i <= amount; i++) {
    projects.push(
      createProject({
        id: i,
      }),
    );
  }
  return projects;
}

export function createProjectStats(
  stats?: DeepPartial<ProjectStats>,
): ProjectStats {
  return {
    tasks: {
      count: 0,
      lastSubmittedAt: Date.now().toString(),
      lastUpdated: Date.now().toString(),
      ...stats?.tasks,
      states: {
        acknowledged: 0,
        approved: 0,
        completed: 0,
        delivered: 0,
        inProgress: 0,
        new: 0,
        rejected: 0,
        ...stats?.tasks?.states,
      },
    },
  };
}

export function createProject(project?: Partial<Project>): Project {
  return new Project({
    id: 1,
    name: 'project-1',
    projectType: ProjectType.TRAINING,
    state: ProjectState.ACTIVE,
    projectGroup: {
      id: 1,
      name: 'group-1',
      workflowCode: null,
    },
    client: { id: 1, name: 'client-1', code: 'AAA' },
    ...project,
  });
}

export function createAttachment(
  attachment?: Partial<IssueAttachment>,
): IssueAttachment {
  return new IssueAttachment({
    id: '11111111-1111-1111-1111-111111111111',
    filename: 'filename.png',
    type: 'image/png',
    s3ObjectKey: 'filename.png',
    issueId: '11111111-1111-1111-1111-111111111111',
    preSignedUrl: 'https://s3.amazonaws.com',
    ...attachment,
  });
}

export function createComment(comment?: Partial<IssueComment>): IssueComment {
  return new IssueComment({
    id: '11111111-1111-1111-1111-111111111111',
    issueId: '11111111-1111-1111-1111-111111111111',
    text: 'test comment',
    isSolution: false,
    createdBy: 1,
    createdAt: '2023-07-27T18:22:51.104Z',
    updatedBy: 1,
    updatedAt: '2023-07-27T18:22:51.104Z',
    attachments: [createAttachment()],
    userFullName: 'John Doe',
    ...comment,
  });
}

export function createPagedResult<T>(
  res?: Partial<FindPageResult<T>>,
): FindPageResult<T> {
  const data = res?.data || [];
  return {
    data: data,
    total: data.length,
    count: data.length,
    page: 1,
    pageCount: 1,
    ...res,
  };
}

export function createInternalUser(
  internalUser?: Partial<InternalUser>,
): InternalUser {
  return {
    id: 1,
    firstName: 'John',
    lastName: 'Smith',
    employeeId: 'AAAA1111',
    platformRoles: [
      {
        name: 'Admin',
      },
      {
        name: 'Agent',
      },
      {
        name: 'Engineer',
      },
      {
        name: 'Supervisor',
      },
      {
        name: 'Supervisor PM',
      },
    ],
    ...internalUser,
  };
}
