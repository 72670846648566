import { Observable, ReplaySubject, of } from 'rxjs';
import { Project } from '../../models/project/project.model';
import { ITab } from '../current-managed-project.service';

export class CurrentManagedProjectServiceMock {
  public projectSubject = new ReplaySubject<Project>(1);

  constructor() {}

  public getTabs$(currentUrl: string): Observable<ITab[]> {
    return of([]);
  }

  public getSamahubUrl(): string {
    return PORTAL_CONFIG.samahubUrl;
  }

  public resetProject(): void {}

  public async loadProject(projectId: number): Promise<void> {}
}
