import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogsModule } from '@sama/common';
import { SamaSelectModule, SamaTableModule } from '@sama/ui-library';
import { ProjectStepsDialogComponent } from './project-steps-dialog/project-steps-dialog.component';

@NgModule({
  declarations: [ProjectStepsDialogComponent],
  exports: [ProjectStepsDialogComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    DialogsModule,
    SamaTableModule,
    SamaSelectModule,
    MatButtonModule,
  ],
})
export class AnnotateModule {}
