import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Icons } from '@sama/common';
import { forkJoin, map, Observable, take } from 'rxjs';
import * as fromRoot from '../store/reducers';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private store: Store<fromRoot.State>) {}

  getProfile$(): Observable<Profile> {
    const userName$ = this.store
      .select((state) => state.auth.user)
      .pipe(
        take(1),
        map((user) => user?.name ?? ''),
      );

    return forkJoin([userName$]).pipe(
      map(([userName]) => this.createProfile(userName)),
    );
  }

  private createProfile(userName: string): Profile {
    return {
      svgIcon: Icons.AvatarLogo,
      userName: userName,
    };
  }
}

export interface Profile {
  userName: string;
  svgIcon: string;
}
