import { Action, createReducer, on } from '@ngrx/store';
import { changeActiveProject } from './projects.actions';

export const projectsFeatureKey = 'projects';

export interface ProjectsState {
  currentProjectId?: number;
  currentSamahubUrl?: string;
}

export const ProjectsInitialState: ProjectsState = {
  currentProjectId: undefined,
  currentSamahubUrl: PORTAL_CONFIG.samahubUrl,
};

const _projectsReducer = createReducer(
  ProjectsInitialState,

  on(changeActiveProject, (state, { projectId, samahubUrl }) => ({
    ...state,
    currentProjectId: projectId,
    currentSamahubUrl: samahubUrl,
  })),
);

export function projectsReducer(
  state: ProjectsState | undefined,
  action: Action,
) {
  return _projectsReducer(state, action);
}
