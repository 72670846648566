import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Profile, ProfileService } from '../../../../services/profile.service';

import { BreadcrumbService, UserManagerService } from '@sama/common';
import { EventTrackingService } from '@sama/ngx-events';
import { NavigationOptions } from '@sama/ui-library';
import { Subscription } from 'rxjs';
import { HelpItemsService } from '../../../../services/help-items.service';
import { SecondLevelService } from '../../../../services/second-level.service';
import { SettingsItemsService } from '../../../../services/settings-items.service';

@Component({
  selector: 'app-authenticated-layout',
  templateUrl: './authenticated-layout.component.html',
  styleUrls: ['./authenticated-layout.component.scss'],
})
export class AuthenticatedLayoutComponent implements OnInit, OnDestroy {
  buttonText = 'upgrade';
  shouldDisplayLicense = false;
  helpItems!: NavigationOptions[];
  profile!: Profile;
  subscriptions: Array<Subscription> = [];
  sourceTitle: string | null = null;
  productName = 'Sama';
  protected breadCrumbConfig$ = this.breadCrumbService.config$;
  protected secondLevelConfig$ = this.secondLevelService.config$;
  protected settingsItemsConfig$ = this.settingsItemsService.config$;
  protected emptyNavigation = this.breadCrumbService.emptyNavigation;
  constructor(
    private profileService: ProfileService,
    private helpItemsService: HelpItemsService,
    private eventTrackingService: EventTrackingService,
    private secondLevelService: SecondLevelService,
    private breadCrumbService: BreadcrumbService,
    private settingsItemsService: SettingsItemsService,
    private router: Router,
    public userManagerService: UserManagerService,
  ) {
    this.subscriptions.push(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.sourceTitle = this.getPageTitle(
            this.router.routerState.snapshot.root,
          );
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.profileService
        .getProfile$()
        .subscribe((profile) => (this.profile = profile)),
    );

    this.helpItems = this.helpItemsService.getHelpItems();
  }

  onAppBarItemClicked(name: string, properties: Object): void {
    this.eventTrackingService.track(name + '_go_ui', {
      source: this.sourceTitle,
      ...properties,
    });
  }

  getPageTitle(routeSnapshot: ActivatedRouteSnapshot): string {
    let sourceTitle =
      routeSnapshot.data && routeSnapshot.data.title
        ? routeSnapshot.data.title
        : null;
    if (routeSnapshot.firstChild) {
      sourceTitle =
        this.getPageTitle(routeSnapshot.firstChild) || this.sourceTitle;
    }

    return sourceTitle;
  }
}
